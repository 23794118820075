import React, { useEffect, useRef, useState } from "react";
import "./viewStyles/scholarship.css";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import SubHeader from "./subheader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ScholarshipFormValidations from "../serviceApi/scholarshipFormValidation";
import { popularBranches } from "../serviceApi/constant";
import { getPersonalInfoById, scholarshipFormPost } from "../serviceApi/Auth-Services";
import Swal from "sweetalert2";
import swal from "sweetalert";
import SpinnerWrapper from "./spinner";
import { useLocation, useNavigate } from "react-router-dom";

function ScholarshipForm() {
  const userId = localStorage.getItem("userDetails");
  console.log(userId);
  const styles = {
    height: "100px",
    resize: "none",
  };
  const mandatoryStyles = {
    color: "red",
  };
  const mandatory = <span style={mandatoryStyles}>*</span>;

  const navigate = useNavigate();
  const firstInputRef = useRef();
  const [semester, setSemester] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    address: "",
    branch: "",
  });
  const { UseScholarshipFormInitialValues, scholarshipFulValidationSchema } =
    ScholarshipFormValidations();

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };
  const handleFileChange1 = (event) => {
    console.log(event.target.files[0]);
    setFile1(event.target.files[0]);
  };
  const handleChange = (e) => {
    const input = e.target.value;
    if (input === "" || (parseInt(input) >= 1 && parseInt(input) <= 12)) {
      setSemester(input);
    }
  };
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const handleScholarshipSubmit = (values, { resetForm, setFieldError }) => {
    console.log("Name", values);
    // if (!values.accept) {
    //   // If unchecked, set an error for the accept field
    //   setFieldError("accept", "You must accept the terms and conditions");
    //   // Return early or handle the error as per your requirement
    //   return;
    // }

    const formData = new FormData();
    const reader = new FileReader();
    formData.append("name", values.name.charAt(0).toUpperCase() + values.name.slice(1));
    formData.append("reg_no", values.reg_no.toUpperCase());
    formData.append("userId", values.userId);
    formData.append("address", values.address);
    formData.append("semester", parseInt(values.semester));
    formData.append("branch", values.branch);
    formData.append("dob", values.dob);
    formData.append("mobile_no", values.mobile_no);
    formData.append("landline_no", values.landline_no);
    formData.append("email", values.email);
    formData.append("year_of_join", parseInt(values.year_of_join));
    formData.append(
      "bank_name",
      values.bank_name.charAt(0).toUpperCase() + values.bank_name.slice(1)
    );
    formData.append(
      "bank_branch",
      values.bank_branch.charAt(0).toUpperCase() + values.bank_branch.slice(1)
    );
    formData.append("cbs_acc_no", values.cbs_acc_no);
    formData.append("ifsc_code", values.ifsc_code.toUpperCase());
    formData.append(
      "father_name",
      values.father_name.charAt(0).toUpperCase() + values.father_name.slice(1)
    );
    formData.append("father_occ", values.father_occ);
    formData.append("father_month_inc", values.father_month_inc);
    formData.append("father_annual_inc", values.father_annual_inc);
    formData.append(
      "mother_name",
      values.mother_name.charAt(0).toUpperCase() + values.mother_name.slice(1)
    );
    formData.append("mother_occ", values.mother_occ);
    formData.append("mother_month_inc", values.mother_month_inc);
    formData.append("mother_annual_inc", values.mother_annual_inc);
    formData.append(
      "guardian_name",
      values.guardian_name.charAt(0).toUpperCase() + values.guardian_name.slice(1)
    );
    formData.append("guardian_occ", values.guardian_occ);
    formData.append("guardian_month_inc", values.guardian_month_inc);
    formData.append("guardian_annual_inc", values.guardian_annual_inc);
    formData.append("cgpa_marks", values.cgpa_marks);
    formData.append("type_of_adminssion", values.type_of_adminssion);
    formData.append("parental_status", values.parental_status);
    formData.append("fees_per_year", values.fees_per_year);
    // const fileAsBlob = new Blob([reader?.result], { type: file?.type });
    // formData.append("proof", fileAsBlob, file?.name);
    formData.append("proof", file);
    formData.append("member_name_relation", values.member_name_relation);
    formData.append("abt_family_financial", values.abt_family_financial);
    // formData.append("marksheet", fileAsBlob, file?.name);
    formData.append("marksheet", file1);
    formData.append("scholarship_name", values.scholarship_name);
    formData.append("periodicity", values.periodicity);
    formData.append("amount", values.amount);
    formData.append("accept", values.accept);
    console.log("before passing in api====>", Object.fromEntries(formData));
    // const formDataValues = Object.fromEntries(formData);
    scholarshipFormPost(formData, userId)
      .then((data) => {
        setIsLoading(true);
        if (data.status === "success") {
          swal("Thank you!", "Your submission has been sent.", "success");
          console.log(data);
          setIsLoading(false);
        } else {
          swal("Error", data.message, "error");
          console.error(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        swal("Failed!", err + " please try again later.", "error");
        setIsLoading(false);
      });
    // resetForm();
  };
  // const deadlineDate = new Date("2024-07-30T00:00:00");
  // const isDeadlinePassed = () => {
  //   const currentDate = new Date();
  //   console.log(currentDate);
  //   return currentDate > deadlineDate;
  // };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Scholarship" />
      {isLoading && <SpinnerWrapper />}
      <div className="site-scholarship container">
        {/* {isDeadlinePassed() ? (
          <div style={{height:'50vh'}} className="d-flex justify-content-center align-items-center">
            <h5 className="text-white">
              The deadline for the scholarship form submission has passed. You can no longer
              submit the form.
            </h5>
          </div>
        ) : ( */}
        <div className="row">
          <div className="scholarship-form-container col-md-12">
            <div className="f-title-description b-title-description title-scholarship">
              Scholarship Form
            </div>
            <Formik
              initialValues={UseScholarshipFormInitialValues()}
              validationSchema={scholarshipFulValidationSchema}
              onSubmit={handleScholarshipSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setValues,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="container-fluid">
                      {/* <div className="col-md-4 d-md-flex justify-content-center align-items-center mt-3 mb-2">
                      <div className="col-8 mt-3 mb-2 sub-heading">
                        Personal Info
                      </div>
                       </div> */}
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Name of the applicant{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            spellCheck="false"
                            name="name"
                            id="name"
                            type="text"
                            autoComplete="off"
                            max="7"
                            innerRef={firstInputRef}
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                              console.log(e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Registration number{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="reg_no"
                            id="reg_no"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("reg_no", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="reg_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Residential address{mandatory}
                          </label>
                          <Field
                            as="textarea"
                            className="form-control"
                            style={styles}
                            name="address"
                            id="address"
                            type="text"
                            rows="4"
                            cols="50"
                            autoComplete="off"
                            maxLength={100}
                            onChange={(e) => {
                              setFieldValue("address", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Semester{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="semester"
                            id="semester"
                            type="number"
                            min="1"
                            max="12"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("semester", e.target.value);
                            }}
                            // value={semester}
                            // onChange={handleChange}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="semester"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Branch{mandatory}
                          </label>
                          <Field
                            className="form-control branch-field"
                            name="branch"
                            id="branch"
                            as="select" // Render as a select dropdown
                            type="text"
                            onChange={(e) => {
                              setFieldValue("branch", e.target.value);
                            }}
                          >
                            <option value="" className="text-white">
                              Select Branch
                            </option>{" "}
                            {popularBranches.map((branch, index) => (
                              <option key={index} value={branch} className="text-white">
                                {branch}
                              </option>
                            ))}
                          </Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="branch"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Date of birth{mandatory}
                          </label>
                          <Field
                            className="form-control date-field"
                            name="dob"
                            id="dob"
                            type="date"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("dob", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage name="dob" component="div" className="text-danger" />
                          </div>
                        </div>
                        {/* <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                        <label
                          className="col-form-label label-text text-white"
                          id="label-text"
                        >
                          Date of birth
                        </label>
                        <div className="d-flex gap-2 col-8">
                          <Field
                            as="select"
                            className="form-control date-field"
                            name="dob_day"
                            id="dob_day"
                            autoComplete="off"
                            style={{ background: "#1A1A1A", border: "none", color: "#fff" }}
                          >
                            <option value="">DD</option>
                            {days.map((day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </Field>
                          <Field
                            as="select"
                            className="form-control date-field"
                            name="dob_month"
                            id="dob_month"
                            autoComplete="off"
                            style={{ background: "#1A1A1A", border: "none", color: "#fff" }}
                          >
                            <option value="">MM</option>
                            {months.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </Field>
                          <Field
                            as="select"
                            className="form-control date-field"
                            name="dob_year"
                            id="dob_year"
                            autoComplete="off"
                            style={{ background: "#1A1A1A", border: "none", color: "#fff" }}
                          >
                            <option value="">YYYY</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div
                          className="col-12 col-md-12 d-flex align-items-start"
                          style={{ height: "30px" }}
                        >
                          <ErrorMessage
                            name="dob_day"
                            component="div"
                            className="text-danger"
                          />
                          <ErrorMessage
                            name="dob_month"
                            component="div"
                            className="text-danger"
                          />
                          <ErrorMessage
                            name="dob_year"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div> */}
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Mobile number{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="mobile_no"
                            id="mobile_no"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("mobile_no", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="mobile_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Landline number
                          </label>
                          <Field
                            className="form-control"
                            name="landline_no"
                            id="landline_no"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("landline_no", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="landline_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Email{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="email"
                            id="email"
                            type="email"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("email", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Year of joining{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="year_of_join"
                            id="year_of_join"
                            type="number"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("year_of_join", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="year_of_join"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Bank details (Name of the bank)
                          </label>
                          <Field
                            className="form-control"
                            name="bank_name"
                            id="bank_name"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("bank_name", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="bank_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Branch (Bank)
                          </label>
                          <Field
                            className="form-control"
                            name="bank_branch"
                            id="bank_branch"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("bank_branch", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="bank_branch"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            CBS Account Number
                          </label>
                          <Field
                            className="form-control"
                            name="cbs_acc_no"
                            id="cbs_acc_no"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("cbs_acc_no", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="cbs_acc_no"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            IFSC Code
                          </label>
                          <Field
                            className="form-control"
                            name="ifsc_code"
                            id="ifsc_code"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("ifsc_code", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="ifsc_code"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            What is your current parental status?{mandatory}
                          </label>
                          <div className="d-flex justify-content-center align-items-start gap-2 text-white pt-2 flex-column">
                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="parental_status"
                                id="singleParent"
                                type="radio"
                                value="singleParent"
                                onChange={(e) => {
                                  setFieldValue("parental_status", e.target.value);
                                }}
                              />
                              <label className="form-check-label" htmlFor="singleParent">
                                Single parent
                              </label>
                            </div>

                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="parental_status"
                                id="marriedCoParenting"
                                type="radio"
                                value="marriedCoParenting"
                                onChange={(e) => {
                                  setFieldValue("parental_status", e.target.value);
                                }}
                              />
                              <label className="form-check-label" htmlFor="marriedCoParenting">
                                Married/co-parenting
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="parental_status"
                                id="DivorcedCoParenting"
                                type="radio"
                                value="DivorcedCoParenting"
                                onChange={(e) => {
                                  setFieldValue("parental_status", e.target.value);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="DivorcedCoParenting"
                              >
                                Divorced/separated co-parenting
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="parental_status"
                                id="widowed"
                                type="radio"
                                value="widowed"
                                onChange={(e) => {
                                  setFieldValue("parental_status", e.target.value);
                                }}
                              />
                              <label className="form-check-label" htmlFor="widowed">
                                Widowed
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="parental_status"
                                id="other"
                                type="radio"
                                value="other"
                                onChange={(e) => {
                                  setFieldValue("parental_status", e.target.value);
                                  console.log(e.target.value);
                                }}
                              />
                              <label className="form-check-label" htmlFor="other">
                                Other
                              </label>
                            </div>
                          </div>

                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="parental_status"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          {/* <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            UserId of the applicant
                          </label>
                          <Field
                            className="form-control"
                            spellCheck="false"
                            name="userId"
                            id="userId"
                            type="text"
                            autoComplete="off"
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="userId"
                              component="div"
                              className="text-danger"
                            />
                          </div> */}
                          {/* <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            User Id of the applicant
                          </label>
                          <input
                            className="form-control"
                            spellCheck="false"
                            name="userId"
                            id="userID"
                            type="text"
                            autoComplete="off"
                            readOnly
                          />
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="userId"
                              component="div"
                              className="text-danger"
                            />
                          </div> */}
                          {/* <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            IFSC Code
                          </label>
                          <Field
                            className="form-control"
                            name="ifsc_code"
                            id="ifsc_code"
                            type="text"
                            autoComplete="off"
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="ifsc_code"
                              component="div"
                              className="text-danger"
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="f-title-description b-title-description title-scholarship mt-5 mb-4">
                        Father Info
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Name of your father{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="father_name"
                            id="father_name"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("father_name", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="father_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Occupation of your father (Answer nil if you could not fill out
                            this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="father_occ"
                            id="father_occ"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("father_occ", e.target.value);
                              console.log(e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="father_occ"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Monthly income (Answer nil if you could not fill out this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="father_month_inc"
                            id="father_month_inc"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("father_month_inc", e.target.value);
                              console.log(e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="father_month_inc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Annual income (Answer nil if you could not fill out this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="father_annual_inc"
                            id="father_annual_inc"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("father_annual_inc", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="father_annual_inc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 d-md-flex justify-content-center align-items-center mt-3 mb-2">
                  <div className="col-8 mt-3 mb-2 text-white fs-4">
                    Mother Info
                  </div>
                </div> */}
                      <div className="f-title-description b-title-description title-scholarship mt-5 mb-4">
                        Mother Info
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Name of your mother{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="mother_name"
                            id="mother_name"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("mother_name", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="mother_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Occupation of your mother (Answer nil if you could not fill out
                            this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="mother_occ"
                            id="mother_occ"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("mother_occ", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="mother_occ"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Monthly income (Answer nil if you could not fill out this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="mother_month_inc"
                            id="mother_month_inc"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("mother_month_inc", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="mother_month_inc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Annual income (Answer nil if you could not fill out this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="mother_annual_inc"
                            id="mother_annual_inc"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("mother_annual_inc", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="mother_annual_inc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 d-md-flex justify-content-center align-items-center mt-3 mb-2">
                  <div className="col-8 mt-3 mb-2 text-white fs-4">
                    Guardian Info
                  </div>
                </div> */}
                      <div className="f-title-description b-title-description title-scholarship mt-5 mb-4">
                        Guardian Info
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Name of your guardian{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="guardian_name"
                            id="guardian_name"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("guardian_name", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="guardian_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Occupation of your guardian (Answer nil if you could not fill out
                            this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="guardian_occ"
                            id="guardian_occ"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("guardian_occ", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="guardian_occ"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Monthly income (Answer nil if you could not fill out this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="guardian_month_inc"
                            id="guardian_month_inc"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("guardian_month_inc", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="guardian_month_inc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Annual income (Answer nil if you could not fill out this){mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="guardian_annual_inc"
                            id="guardian_annual_inc"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("guardian_annual_inc", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="guardian_annual_inc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 d-md-flex justify-content-center align-items-center mt-3 mb-2">
                  <div className="col-8 mt-3 mb-2 text-white fs-4">
                    Qualification Info
                  </div>
                </div> */}
                      <div className="f-title-description b-title-description title-scholarship mt-5 mb-4">
                        Qualification Info
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            +12 marks or CGPA{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="cgpa_marks"
                            id="cgpa_marks"
                            type="number"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("cgpa_marks", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="cgpa_marks"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Type of admission{mandatory}
                          </label>
                          <div className="d-flex justify-content-center align-items-end gap-2 text-white pt-2">
                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="type_of_adminssion"
                                id="admissionTypeCentac"
                                type="radio"
                                value="true"
                                onChange={(e) => {
                                  setFieldValue("type_of_adminssion", e.target.value);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="admissionTypeCentac"
                              >
                                Centac
                              </label>
                            </div>

                            <div className="form-check">
                              <Field
                                className="form-check-input no-box-shadow"
                                name="type_of_adminssion"
                                id="admissionTypeJossa"
                                type="radio"
                                value="false"
                                onChange={(e) => {
                                  setFieldValue("type_of_adminssion", e.target.value);
                                }}
                              />
                              <label className="form-check-label" htmlFor="admissionTypeJossa">
                                Jossa
                              </label>
                            </div>
                          </div>

                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="type_of_adminssion"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            College Fees / per year{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="fees_per_year"
                            id="fees_per_year"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("fees_per_year", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="fees_per_year"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Proof of income (Attach salary slip/pic certificate){mandatory}
                          </label>
                          {/* <input
                          type="file"
                          onChange={handleFileChange}
                          /> */}
                          <input
                            className="form-control"
                            name="proof"
                            id="proof"
                            type="file"
                            autoComplete="off"
                            onChange={handleFileChange}
                          />
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            {/* <ErrorMessage name="proof" component="div" className="text-danger" /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Name of the members and their relationship{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="member_name_relation"
                            id="member_name_relation"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("member_name_relation", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="member_name_relation"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Would you like to say anything specific about your family financial
                            condition?{mandatory}
                          </label>
                          <Field
                            as="textarea"
                            className="form-control"
                            name="abt_family_financial"
                            id="abt_family_financial"
                            type="text"
                            autoComplete="off"
                            rows="4"
                            cols="50"
                            style={styles}
                            onChange={(e) => {
                              setFieldValue("abt_family_financial", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="abt_family_financial"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            First & second semester mark details (Merge all mark statements as
                            a PDF and upload){mandatory}
                          </label>
                          {/* <input
                          type="file"
                          onChange={handleFileChange1}
                          /> */}
                          <input
                            className="form-control"
                            name="marksheet"
                            id="marksheet"
                            type="file"
                            autoComplete="off"
                            onChange={handleFileChange1}
                          />
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            {/* <ErrorMessage
                              name="marksheet"
                              component="div"
                              className="text-danger"
                            /> */}
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Other scholarship received{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="scholarship_name"
                            id="scholarship_name"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("scholarship_name", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="scholarship_name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Periodicity{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="periodicity"
                            id="periodicity"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("periodicity", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="periodicity"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          <label
                            className="col-form-label label-text text-white"
                            id="label-text"
                          >
                            Amount{mandatory}
                          </label>
                          <Field
                            className="form-control"
                            name="amount"
                            id="amount"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              setFieldValue("amount", e.target.value);
                            }}
                          ></Field>
                          <div
                            className="col-12 col-md-12 d-flex align-items-start"
                            style={{ height: "30px" }}
                          >
                            <ErrorMessage
                              name="amount"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-md-flex justify-content-evenly align-items-center">
                        <div className="col-md-5 d-flex justify-content-center align-items-start flex-column gap-1">
                          {/* <label
                        className="col-form-label label-text text-white"
                        id="label-text"
                      >
                        Amount
                      </label>
                      <input
                        className="form-control"
                        name="amount"
                        type="number"
                        autoComplete="off"
                      /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4 d-flex justify-content-center align-items-center gap-1">
                      <div className="col-md-1 d-flex justify-content-end align-items-center">
                        <Field
                          type="checkbox"
                          name="accept"
                          id="accept"
                          onChange={(e) => {
                            // Use e.target.checked to get the current checked state
                            setFieldValue("accept", e.target.checked);
                          }}
                        />
                      </div>
                      <div className="col-md-11 p-3 text-white" id="label-text">
                        I hereby declare the information provided above is true to the best of
                        my knowledge.{mandatory}
                      </div>
                    </div>
                    <div
                      className="col-md-5 d-flex justify-content-center align-items-center "
                      style={{ height: "10px" }}
                    >
                      <div className="">
                        <ErrorMessage name="accept" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-4 d-flex justify-content-center align-items-center gap-4">
                      <button
                        type="submit"
                        className="btn btn-block btn-success"
                        name="scholarship-button"
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        className="btn btn-block btn-success"
                        name="scholarship-button"
                        onClick={() => {
                          navigate(`/edit-scholarship-form?id=${userId}`);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        {/* )} */}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ScholarshipForm;
