import { useMemo } from "react";
import * as Yup from "yup";
import {
  CHARACTER_VALIDATION_ADDRESS,
  TOP_EMAIL_DOMAINS,
  VALIDATE_ADDRESS,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_INVALID,
  VALIDATE_NAME,
  VALIDATE_NAME_LENGTH,
  VALIDATE_NAME_MAX,
  VALIDATE_NAME_NUMBERS,
  VALIDATE_REGISTER_NUMBER,
  VALIDATE_SEMESTER,
  VALIDATE_WHITE_SPACE,
} from "./constant";

const EditScholarshipFormValidations = () => {
  const currentYear = new Date().getFullYear();
  const scholarshipValidations = () => {
    return {
      //Completed
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, VALIDATE_NAME_NUMBERS)
        .max(26, VALIDATE_NAME_MAX)
        .min(3, VALIDATE_NAME_LENGTH)
        .required(VALIDATE_NAME)
        .test(
          "no-leading-space",
          VALIDATE_WHITE_SPACE,
          (value) => value && value.trim() === value
        ),
      //Completed
      reg_no: Yup.string().required(VALIDATE_REGISTER_NUMBER),
      // Completed
      address: Yup.string()
        .min(10, "Residential address must be at least 10 characters")
        .max(100, CHARACTER_VALIDATION_ADDRESS)
        .required(VALIDATE_ADDRESS),
      //Completed
      semester: Yup.number()
        .required(VALIDATE_SEMESTER)
        .integer("Semester must be an number")
        .min(1, "Invaild semester")
        .max(12, "Invaild semester"),
      //Completed
      branch: Yup.string().required("Branch is required"),
      //Completed
      dob: Yup.date()
        .required("Please select your Date of Birth")
        .max(new Date(), `Date of Birth cannot be after ${new Date().toLocaleDateString()}`),
      mobile_no: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"), // Validates the length and format
      //Completed
      landline_no: Yup.string()
        // .required("Landline number is required")
        // .matches(/^\d{3}-\d{4}-\d{4}$/, "Enter a valid landline number") // Example pattern: XXX-XXXX-XXXX
        .min(8, "Landline number must be at least 8 characters") // Adjust the minimum length as needed
        .max(12, "Landline number must be at most 12 characters"), // Adjust the maximum length as needed
      //Completed
      email: Yup.string()
        .email(VALIDATE_EMAIL_INVALID)
        .required(VALIDATE_EMAIL)
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, VALIDATE_EMAIL_INVALID)
        .max(36, VALIDATE_EMAIL_INVALID)
        .test(
          "no-extra-dots",
          VALIDATE_EMAIL_INVALID,
          (value) => value && /^(?!.*\.\.)(?=.*\.com$).*$/.test(value)
        )
        // .domain(VALIDATE_EMAIL_INVALID),
        .test("is-valid-domain", VALIDATE_EMAIL_INVALID, (value) => {
          if (!value) return true;
          const domain = value.split("@")[1];
          return domain && TOP_EMAIL_DOMAINS[domain];
        }),
      // Completed
      year_of_join: Yup.number()
        .required("Year of joining is required")
        .integer("Year of joining must be an number")
        .typeError("Year of joining must be a number")
        .min(1900, "Invaild year of joining")
        .max(currentYear, `Year of joining must be less than or equal to ${currentYear}`),
      bank_name: Yup.string(),
      // required("Bank name is required"),
      bank_branch: Yup.string(),
      // .required("Bank branch is required"),
      cbs_acc_no: Yup.string()
        // .required("Account number is required")
        .matches(/^\d{9,18}$/, "Enter a valid account number"), // Example pattern: 9 to 18 digits
      ifsc_code: Yup.string()
        // .required("IFSC code is required")
        .matches(/^[A-Za-z]{4}\d{7}$/, "Enter a valid IFSC code"), // Example pattern: 4 alphabets followed by 7 digits
      father_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, VALIDATE_NAME_NUMBERS)
        .max(26, VALIDATE_NAME_MAX)
        .min(3, VALIDATE_NAME_LENGTH)
        .required("Father's name is required")
        .test(
          "no-leading-space",
          VALIDATE_WHITE_SPACE,
          (value) => value && value.trim() === value
        ),
      father_occ: Yup.string().required("Occupation is required"),
      father_month_inc: Yup.string().required("Father's monthly income is required"),
      father_annual_inc: Yup.string().required("Father's annual income required"),
      mother_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, VALIDATE_NAME_NUMBERS)
        .max(26, VALIDATE_NAME_MAX)
        .min(3, VALIDATE_NAME_LENGTH)
        .required("Mother's name is required")
        .test(
          "no-leading-space",
          VALIDATE_WHITE_SPACE,
          (value) => value && value.trim() === value
        ),
      mother_occ: Yup.string().required("Occupation is required"),
      mother_month_inc: Yup.string().required("Mother's monthly income is required"),
      mother_annual_inc: Yup.string().required("Mother's annual income is required"),
      guardian_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, VALIDATE_NAME_NUMBERS)
        .max(26, VALIDATE_NAME_MAX)
        .min(3, VALIDATE_NAME_LENGTH),
      guardian_occ: Yup.string(),
      // .required("Occupation is required"),
      guardian_month_inc: Yup.string(),
      // .required("Guardian's monthly income is required"),
      guardian_annual_inc: Yup.string(),
      // .required("Guardian's annual income required"),
      income_proof: Yup.mixed(),
      // .required("Proof of income is required"),
      member_name_relation: Yup.string().required("Members name is required"),
      abt_family_financial: Yup.string().required("Family description is required"),
      cgpa_marks: Yup.number()
        .required("CGPA or marks is required")
        .test("valid-cgpa-or-marks", "Invalid marks or cgpa", function (value) {
          const { createError } = this;

          const cgpaRegex = /^([0-9]|[0-9][0-9]|10)(\.[0-9]{1,2})?$/;

          const isValidCGPA = value >= 0 && value <= 10 && cgpaRegex.test(value);

          const isValidMarks = value >= 0 && value <= 600;

          if (isValidCGPA || isValidMarks) {
            return true;
          }

          return createError({ message: "Invalid CGPA or marks" });
        }),
      type_of_adminssion: Yup.string().required("Please select the type of admission"),
      parental_status: Yup.string().required("Please select the parental status"),
      fees_per_year: Yup.number()
        .typeError("College Fees must be a number")
        .positive("College Fees must be a positive number")
        .min(10, "College Fees must be at least 10") // Ensure it’s at least two digits
        .max(600000, "College Fees is invalid")
        .required("College Fees is required"),
      marksheet: Yup.mixed(),
      // required("Please upload the marksheet"),
      scholarship_name: Yup.string().required("Scholarship name is required"),
      periodicity: Yup.string().required("Periodicity is required"),
      amount: Yup.string().required("Amount is required"),
      accept: Yup.string().required("Please accept the terms and conditions"),
    };
  };

  const UseScholarshipFormInitialValues = () => {
    const initialValues = {
      // Personal Info
      name: "",
      reg_no: "",
      address: "",
      semester: "",
      branch: "",
      dob: "",
      mobile_no: "",
      landline_no: "",
      email: "",
      year_of_join: "",
      bank_name: "",
      bank_branch: "",
      cbs_acc_no: "",
      ifsc_code: "",
      father_name: "",
      father_occ: "",
      father_month_inc: "",
      father_annual_inc: "",
      mother_name: "",
      mother_occ: "",
      mother_month_inc: "",
      mother_annual_inc: "",
      guardian_name: "",
      guardian_occ: "",
      guardian_month_inc: "",
      guardian_annual_inc: "",
      cgpa_marks: "",
      type_of_adminssion: "",
      parental_status:"",
      fees_per_year: "",
      income_proof: "",
      member_name_relation: "",
      abt_family_financial: "",
      marksheet: "",
      scholarship_name: "",
      collegeFees: "",
      periodicity: "",
      amount: "",
      accept: "",
    };
    return initialValues;
  };

  const scholarshipFulValidationSchema = useMemo(() => {
    return Yup.object().shape(scholarshipValidations());
  }, []);
  return {
    scholarshipFulValidationSchema,
    UseScholarshipFormInitialValues,
  };
};

export default EditScholarshipFormValidations;
